/* eslint-disable max-len */
import dayjs from 'dayjs';

import T, { MDText } from 'i18n-react';

import Texts from '../texts.json';

T.setTexts(Texts);

const LocalT = new MDText(Texts);

// eslint-disable-next-line valid-typeof
const type = (v, expected) => typeof v === expected;

export const isNull = (v) => v === null;
export const isNill = (v) => v === null || v === undefined;
export const isObject = (v) => !isNull(v) && type(v, 'object') && !Array.isArray(v);
export const isEmptyObject = (v) => (isObject(v) ? !Object.keys(v).length : false);
export const isFunction = (v) => type(v, 'function');
export const isNumber = (v) => type(v, 'number');
export const isUndefined = (v) => v === undefined;
export const isBoolean = (v) => !isNull(v) && type(v, 'boolean');
export const isEven = (v) => (v % 2 === 0);
export const isString = (value) => type(value, 'string') || value instanceof String;
export const isEmptyString = (value) => (isString(value) ? value.toString() === '' : false);

export const timeElapsed = (date, time) => dayjs().diff(date, `${time}`);

export const formatPhoneNumber = (number, allowDefaultNumber = true) => {
  if (!number && !allowDefaultNumber) {
    return { number: '', pretty: '' };
  }

  const internationalRegex = /^(\+44|0044)/;
  const phoneNumber = number || LocalT.translate('buyers.motorway.landlineNumber');
  let phoneNumberNoSpaces = phoneNumber.replace(/ /g, '');
  let formattedPhoneNumber = '';

  const isInternational = phoneNumberNoSpaces.match(internationalRegex)?.[0];

  if (isInternational) {
    phoneNumberNoSpaces = phoneNumberNoSpaces.replace(internationalRegex, 0);
  }

  switch (true) {
    case phoneNumberNoSpaces.startsWith('03'):
    case phoneNumberNoSpaces.startsWith('08'):
    case phoneNumberNoSpaces.startsWith('09'):
    case phoneNumberNoSpaces.startsWith('011'):
      formattedPhoneNumber = `${phoneNumberNoSpaces.substring(0, 4)} ${phoneNumberNoSpaces.substring(4, 7)} ${phoneNumberNoSpaces.substring(7)}`;
      break;
    case phoneNumberNoSpaces.startsWith('02'):
    case phoneNumberNoSpaces.startsWith('055'):
    case phoneNumberNoSpaces.startsWith('056'):
      formattedPhoneNumber = `${phoneNumberNoSpaces.substring(0, 3)} ${phoneNumberNoSpaces.substring(3, 7)} ${phoneNumberNoSpaces.substring(7)}`;
      break;
    case phoneNumberNoSpaces.startsWith('07'):
      formattedPhoneNumber = `${phoneNumberNoSpaces.substring(0, 5)} ${phoneNumberNoSpaces.substring(5, 8)} ${phoneNumberNoSpaces.substring(8)}`;
      break;
    default:
      formattedPhoneNumber = phoneNumberNoSpaces;
      break;
  }

  if (isInternational) {
    formattedPhoneNumber = formattedPhoneNumber.replace(/^0/, `${isInternational} `);
  }

  return {
    number: phoneNumberNoSpaces,
    pretty: formattedPhoneNumber,
  };
};

// Fastlane helpers
export const sortVehiclesByUpdatedBy = (vehicles = []) => vehicles
  .sort((a, b) => (new Date(b.enquiry.updatedAt) - new Date(a.enquiry.updatedAt)));

export const mapEligibilityToVehicle = (vehicles) => {
  if (!vehicles) {
    return vehicles;
  }

  const isVehicleArray = Array.isArray(vehicles);

  const mapped = (isVehicleArray ? vehicles : [vehicles]).map((v) => {
    const { marketplaceEligible } = v?.enquiry || {};

    if (!marketplaceEligible) {
      return v;
    }

    return { ...v, marketplaceEligible };
  });

  return isVehicleArray ? mapped : mapped[0];
};

export const vehicleType = (str) => (str.includes('van') ? 'van' : 'car');

export const REVIEW_AUTHOR_MAX_LENGTH = 25;
export const REVIEW_BODY_MAX_LENGTH = 160;
export const REVIEW_TITLE_MAX_LENGTH = 50;

export const truncate = (str, max, suffix = '...') => (str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`);

const VOLKSWAGEN = ['Volkswagen', 'VW', 'Volks Wagen'];
const LAND_ROVER = ['Land Rover', 'Range Rover'];
export const SPECIFIC_MAKE_NAMES = [...VOLKSWAGEN, ...LAND_ROVER];

export const preparedDisplayNames = (word) => {
  if (VOLKSWAGEN.includes(word)) {
    return VOLKSWAGEN;
  }
  if (LAND_ROVER.includes(word)) {
    return LAND_ROVER;
  }

  return [word];
};

export const isSSR = () => typeof window === 'undefined';
export const useCoarsePointer = () => !isSSR() && window.matchMedia('(pointer: coarse)').matches;
export const isApplicationNonProd = process.env.APPLICATION_ENVIRONMENT !== 'production';

export const clientSidePDR = () => {
  if (!globalThis.window) {
    // eslint-disable-next-line no-console
    console.warn('Warning! You are using client side DPR helper!');
  }

  return globalThis?.devicePixelRatio || 1;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getStrippedUserData = (user) => {
  const { email, firstName, id, lastName, name, ...anonymousData } = (user || {});
  return {
    id,
    ...(id === undefined ? { email } : {}),
    ...anonymousData,
  };
};

export const NO_PAGE_TITLE_SUFFIX_PATHS = Object.freeze([
  '/',
  '/how-it-works',
]);

export const TRUSTPILOT_REVIEW_NUMBER = 75000;
export const TRUSTPILOT_REVIEW_SCORE = 4.6;

export const VRM_REGEX = /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)/i;
