/* eslint-disable no-underscore-dangle, react/jsx-filename-extension */

import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Make sure this image is pulled into dist
import './assets_seller/og-website-image-4.5.png';

import { OptimizelyProvider } from '@optimizely/react-sdk';

import { ConfigProvider } from 'Context/config';
import { UserProvider } from 'Context/user';
import { VehicleLookupProvider } from 'Context/vehicleLookup';
import { VehicleProvider } from 'Context/vehicles';

import { optimizelyInstanceFactory } from 'Utilities/analytics/optimizely/optimizely';
import { optimizelyAttributes } from 'Utilities/analytics/optimizely/optimizely.helper';
import { fastlaneFormatIntState } from 'Utilities/fastlane';
import { User } from 'Utilities/user/@types';

import App from '../shared/containers/app/App';
import Status500 from '../shared/containers/app/error/500';
import ErrorBoundary from '../shared/containers/app/error/ErrorBoundary';
import Checkout from '../shared/containers/checkout/Checkout';
import { StoreProvider } from '../shared/store';

import { getOptimizelyUserId, InitialContext, InitialState } from './index.helper';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var Sentry: {
    captureException: (error: unknown, sentryOpts?: unknown) => void;
    configureScope?: (callback: (scope: { getUser: Function; setUser: Function }) => void) => void;
  };

  // eslint-disable-next-line vars-on-top, no-var
  var DD_RUM: {
    addAction: (action: string, context?: Record<string, any>) => void;
    addError: (error: unknown, context?: Record<string, any>) => void;
    onReady: (callback: () => void) => void;
    setUser: ({ id }: { id?: string | number }) => void;
  };
  interface Window {
    Sentry: {
      captureException: Function;
      captureMessage: Function;
      configureScope: Function;
    };
    __CONTAINER__: string;
    __CONTEXT__: InitialContext;
    __ERROR__?: boolean;
    __INITIAL_STATE__: InitialState;
    __SP_SESSION_TIME__: number;
    dataLayer: Record<string, any>[];
    mswStop?: Function;
    snowplow: Function;
  }
}

export const init = () => {
  const { history } = window;

  // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  // Stop <= iOS 15.4 from booting
  if ([
    !('IntersectionObserver' in window),
    !('locks' in navigator),
    !(window.CSS && CSS.supports('appearance', 'none')),
  ].some((v) => v)) {
    return;
  }

  const initialState = window.__INITIAL_STATE__;
  const container = window.__CONTAINER__;
  const { vehicles, ...user } = initialState?.user ?? {} as User;
  const $app = document.getElementById('app') || document.body;

  const optimizelyInstance = optimizelyInstanceFactory(initialState.optimizelySdk);
  const optimizelyUserId = getOptimizelyUserId();

  const optimizelyUser = {
    attributes: { ...optimizelyAttributes({ user }) },
    id: optimizelyUserId,
  };

  if (container === 'checkout') {
    const data = window.__CONTEXT__;
    hydrateRoot($app, (<Checkout {...{ ...data }} />));
  } else if (container === 'error-page') {
    hydrateRoot($app, (<Status500 />));
  } else if (container === 'total-error') {
    hydrateRoot($app, (<p>Sorry, the page encountered an error, please try refreshing</p>));
  } else {
    // Do not use StoreProvider going forward!! Want to kill with 🔥🔥🔥🔥
    hydrateRoot(
      $app, (
        (
          <StoreProvider initialState={initialState}>
            <ConfigProvider value={fastlaneFormatIntState(initialState)}>
              <UserProvider value={Object.keys(user).length ? user as User : null}>
                <VehicleProvider value={vehicles}>
                  <VehicleLookupProvider value={vehicles && vehicles[0]}>
                    <BrowserRouter>
                      <OptimizelyProvider optimizely={optimizelyInstance} timeout={3000} user={optimizelyUser}>
                        <ErrorBoundary>
                          <App hasError={window.__ERROR__} />
                        </ErrorBoundary>
                      </OptimizelyProvider>
                    </BrowserRouter>
                  </VehicleLookupProvider>
                </VehicleProvider>
              </UserProvider>
            </ConfigProvider>
          </StoreProvider>
        )),
    );
  }
};

init();
